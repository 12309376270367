import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/Heading.styles';

import { breakpoints, colors, fonts } from '../style-utilities/variables';

const StyledHeading = styled.h1`
  color: ${colors.primary};
  font-size: 36px;
  margin-bottom: 0;
  text-align: center;
  font-family: ${fonts.primary};
  font-weight: bold;
  @media (max-width: ${breakpoints.tablet}) {
    font-size: 28px;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const Heading = ({ children, ...remainProps }) => {
  return <StyledHeading {...remainProps}>{children}</StyledHeading>;
};

export default Heading;
